<template>
  <q-dialog v-model="isShowDialog" persistent>
    <q-card style="width: 300px">
      <q-card-section class="column">
        <h1>{{ actionType ? "Criar segmento" : "Editar segmento" }}</h1>
        <div class="column">
          <div class="row">
            <label for="" class="label-input q-mr-sm">Nome do segmento</label>
            <q-img
              src="@/assets/icons/info_cicle.svg"
              width="16px"
              height="16px"
            />
            <q-tooltip anchor="top middle" self="top middle">
              Crie segmentos com base na localidade, filiais, regionais, etc...
            </q-tooltip>
          </div>
          <q-input
            outlined
            v-model="branchName"
            :rules="[(val) => !!val || 'O nome do segmento é obrigatório']"
          />
        </div>
      </q-card-section>
      <q-card-actions align="around">
        <q-btn label="Cancelar" no-caps v-close-popup outline />
        <q-btn
          no-caps
          :disable="branchName == '' ? true : false"
          :label="actionType ? 'Criar segmento' : 'Editar segmento'"
          color="default-pink"
          @click="handleBranch"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
/* Services */
import GroupServices from "@/services/GroupServices.js";

/* Vue */
import { onMounted, ref, watch } from "vue";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

export default {
  name: "DialogSegment",
  emits: ["cancel", "save"],
  props: {
    actionType: {
      required: true,
      type: Boolean,
    },
    showDialog: {
      required: true,
      type: Boolean,
    },
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const groupServices = new GroupServices();
    const branchName = ref("");
    const Alerts = new AlertsClass();

    const isShowDialog = ref(props.showDialog);

    /* Functions */
    async function handleBranch() {
      if (props.actionType) {
        await groupServices
          .addBranch(branchName.value)
          .then((response) => {
            if (response.data) {
              emit("save");
              Alerts.alertOf("Segmento salvo com sucesso!", "green-9");
            }
          })
          .catch((err) => {
            console.log(err);
            Alerts.alertOf("Erro ao salvar segmento!");
          });
      } else {
        await groupServices
          .editBranch(props.content.id, branchName.value)
          .then((response) => {
            if (response.data) {
              emit("save");
              Alerts.alertOf("Segmento salvo com sucesso!", "green-9");
            }
          })
          .catch((err) => {
            Alerts.alertOf("Erro ao salvar segmento!");
          });
      }
    }

    onMounted(() => {
      if (!props.actionType) {
        branchName.value = props.content.name_branch;
      }
    });

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    return { handleBranch, branchName, isShowDialog };
  },
};
</script>

<style scoped>
h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #262626;
  margin-bottom: 26px;
  text-align: center;
}

.label-input {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #9c9c9c;
  margin-bottom: 8px;
}
</style>
