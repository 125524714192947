<template>
  <q-dialog v-model="isShowDialog" persistent>
    <q-card style="width: 300px">
      <q-card-section class="column">
        <h1>Tem certeza que deseja apagar o segmento?</h1>
        <div class="column q-mt-md">
          <p class="text-center">
            Se você apagar, os grupos pertencentes a ele ficarão sem segmento.
          </p>
        </div>
      </q-card-section>
      <q-card-actions align="around" class="q-pb-md">
        <q-btn label="Cancelar" no-caps v-close-popup outline />
        <q-btn
          no-caps
          label="Apagar"
          color="default-pink"
          @click="handleBranch"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
/* Services */
import GroupServices from "@/services/GroupServices.js";

/* Vue */
import { onMounted, ref } from "vue";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

export default {
  name: "DialogSegment",
  emits: ["cancel", "delete"],
  props: {
    showDialog: {
      required: true,
      type: Boolean,
    },
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const groupServices = new GroupServices();
    const Alerts = new AlertsClass();
    const isShowDialog = ref(props.showDialog);

    /* Functions */
    async function handleBranch() {
      await groupServices
        .disableBranch(props.content)
        .then((response) => {
          if (response.data) {
            emit("delete");
            Alerts.alertOf("Segmento removido com sucesso!", "green-9");
          }
        })
        .catch((err) => {
          Alerts.alertOf("Erro ao salvar segmento!");
        });
    }

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    return { handleBranch, isShowDialog };
  },
};
</script>

<style scoped>
h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #262626;
  margin-bottom: 26px;
  text-align: center;
}

p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #666666;
}
</style>
