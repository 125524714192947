<template>
  <section class="container-moodManagement">
    <div class="self-start">
      <q-btn
        color="default-pink"
        icon-right="add"
        label="Adicionar humor"
        no-caps
        @click="handleDialog('add')"
        v-if="ValidateActionsPage('company-humor-add')"
      />
    </div>
    <q-table
      :rows="rows"
      :rows-per-page-options="[5]"
      :columns="columns"
      :filter="filter"
      row-key="name"
      style="box-shadow: none"
      color="default-pink"
      virtual-scroll
      class="full-width text-center"
    >
      <template v-slot:top-right>
        <div>
          <q-input
            class="col-3 input-search q-ma-sm"
            bg-color="white"
            color="default-pink"
            dense
            type="text"
            placeholder="Pesquisar"
            outlined
            rounded
            v-model="filter"
          >
            <template v-slot:prepend>
              <q-icon name="search" color="default-pink" />
            </template>
          </q-input>
        </div>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="emoji_path" :props="props">
            <q-img
              :src="urlAwsBucket + props.row.emoji_path"
              style="width: 50px; height: 50px; border-radius: 50%"
            />
          </q-td>
          <q-td key="title" :props="props">
            <span class="moodTitle"> {{ props.row.title }}</span>
          </q-td>
          <q-td key="created_at" :props="props">
            <span class="moodTitle">
              {{
                props.row.created_at.slice(0, 10).split("-").reverse().join("/")
              }}</span
            >
          </q-td>
          <q-td key="acao" :props="props">
            <div class="q-gutter-md">
              <q-btn
                unelevated
                rounded
                color="default-pink"
                label="Apagar"
                size="md"
                no-caps
                icon-right="delete"
                outline
                @click="getMoodForDelete(props.row.id)"
                v-if="ValidateActionsPage('company-humor-delete')"
              />
              <q-btn
                unelevatedF
                rounded
                color="default-pink"
                label="Editar"
                size="md"
                no-caps
                icon-right="edit"
                v-if="ValidateActionsPage('company-humor-edit')"
                @click="
                  () => {
                    handleDialog('edit');
                    getMoodForUpdate(props.row);
                  }
                "
              />
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <DialogMood
      v-if="visibilityDialog"
      :confirm="visibilityDialog"
      :actionType="actionType"
      @closeDialog="() => (visibilityDialog = false)"
    />
  </section>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import DialogMood from "../dialogs/DialogMood.vue";

//----UTILS
import { ENV  } from "@/utils/env";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

/* Services */

import UserServices from "@/services/UserServices";

/* vuex */
import { useStore } from "vuex";

import { useQuasar } from "quasar";

export default {
  name: "MoodManagment",
  components: {
    DialogMood,
  },
  setup() {
    const urlAwsBucket =ENV.URL_AWS_BUCKET;
    const canUserAcess = new CanUserAccessClass();
    const userServices = new UserServices();
    const store = new useStore();
    const $q = new useQuasar();

    const filter = ref("");
    const columns = ref([
      {
        name: "emoji_path",
        required: true,
        label: "Imagem",
        field: (row) => row.image_path,
      },
      {
        name: "title",
        required: true,
        label: "Título",
        field: (row) => row.title,
      },
      {
        name: "created_at",
        required: true,
        label: "Criado em",
        field: (row) => row.created_at,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
      },
    ]);
    const rows = ref([]);
    const visibilityDialog = ref(false);
    const actionType = ref();

    function handleDialog(type) {
      visibilityDialog.value = !visibilityDialog.value;
      type === "add" ? (actionType.value = true) : (actionType.value = false);
    }

    function getMoodForUpdate(mood) {
      store.commit("setMood", {
        moodUpdate: { id: mood.id, title: mood.title, path: mood.emoji_path },
      });
    }

    function getMoodForDelete(id) {
      $q.notify({
        message: "Você deseja apagar esse humor?",
        color: "red-9",
        position: "top",
        actions: [
          {
            label: "Sim!",
            color: "yellow",
            handler: async () => {
              await userServices
                .deleteEmoji(id)
                .then((response) => {
                  $q.notify({
                    message: "O humor foi deletado com sucesso!",
                    color: "green",
                    position: "top",
                  });
                  listMood();
                })
                .catch((error) => {
                  console.log(error);
                });
            },
          },
          { label: "Não!", color: "white" },
        ],
      });
    }

    async function ValidateActionsPage(routeName) {
      if (canUserAcess.canUserAccess(routeName, false)) {
        return true;
      }
    }

    async function listMood() {
      await userServices
        .getAllEmoji()
        .then((response) => {
          rows.value = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    /* Lifecycles */

    onMounted(() => {
      listMood();
    });

    const isMoodStorage = computed(() => {
      return store.state.mood.storingMood;
    });

    watch(isMoodStorage, (newValue, oldValue) => {
      if (newValue) {
        listMood();
        store.commit("setMood", { storingMood: false });
      }
    });

    return {
      columns,
      rows,
      filter,
      handleDialog,
      visibilityDialog,
      actionType,
      ValidateActionsPage,
      urlAwsBucket,
      store,
      getMoodForUpdate,
      getMoodForDelete,
    };
  },
};
</script>

<style scoped>
.container-moodManagement {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.moodTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #404040;
}
</style>
