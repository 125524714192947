<template>
  <section class="container-segment">
    <div class="row items-center">
      <q-btn
        color="default-pink"
        icon="add"
        label="Criar segmento"
        no-caps
        class="q-mr-sm"
        @click="handleDialog('add')"
        v-if="true"
      />
      <q-img src="@/assets/icons/info_cicle.svg" width="28px" height="28px" />
      <q-tooltip anchor="top left" self="top left">
        Crie segmentos com base na localidade, filiais, regionais, etc...
      </q-tooltip>
    </div>
    <div class="q-mt-md full-width">
      <Table
        :list="rows"
        :columns="columnsTable"
        :addEdit="true"
        :addRemove="true"
        ><template v-slot:body="props">
          <div>
            <q-btn
              dense
              round
              color="default-pink"
              title="Editar"
              flat
              @click="handleDialog('edit', props.props.row)"
              icon="o_edit_note"
            />
            <q-btn
              dense
              round
              color="default-pink"
              title="Remover"
              flat
              @click="handleDeleteBranch(props.props.row.id)"
              icon="o_delete_outline"
            />
          </div>
        </template>
      </Table>
    </div>
    <DialogSegment
      :showDialog="visibilityDialog"
      v-if="visibilityDialog"
      :actionType="actionType"
      :content="content"
      @save="
        () => {
          getBranches();
          visibilityDialog = !visibilityDialog;
        }
      "
    />
    <DialogDeleteSegment
      :showDialog="visibilityDeleteDialog"
      v-if="visibilityDeleteDialog"
      :content="content"
      @delete="
        () => {
          getBranches();
          visibilityDeleteDialog = !visibilityDeleteDialog;
        }
      "
    />
  </section>
</template>

<script>
/* Vue */
import { onMounted, ref } from "vue";

/* components */
import Table from "@/components/shared/tables/TableDynamic.vue";
import DialogSegment from "@/components/admin/dialogs/DialogSegment.vue";
import DialogDeleteSegment from "@/components/admin/dialogs/DialogDeleteSegment.vue";

/* Services */
import GroupServices from "@/services/GroupServices.js";

export default {
  components: {
    Table,
    DialogSegment,
    DialogDeleteSegment,
  },
  setup() {
    const groupServices = new GroupServices();
    const columnsTable = ref([
      {
        name: "name_branch",
        label: "Nome",
        align: "center",
        field: (row) => row.name_branch,
      },
      {
        name: "created_at",
        label: "Criado em",
        align: "center",
        field: (row) =>
          row.created_at.slice(0, 10).split("-").reverse().join("/"),
      },
      {
        name: "update_at",
        label: "Atualizado em",
        align: "center",
        field: (row) =>
          row.update_at?.slice(0, 10).split("-").reverse().join("/"),
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
      },
    ]);
    const rows = ref([]);
    const visibilityDialog = ref(false);
    const visibilityDeleteDialog = ref(false);
    const actionType = ref();
    const content = ref();

    /* Functions */
    async function getBranches() {
      await groupServices
        .listBranches()
        .then((response) => {
          rows.value = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function handleDialog(type, data) {
      visibilityDialog.value = !visibilityDialog.value;

      if (type === "add") {
        actionType.value = true;
      } else {
        actionType.value = false;
        content.value = data;
      }
    }

    function handleDeleteBranch(data) {
      visibilityDeleteDialog.value = !visibilityDeleteDialog.value;
      content.value = data;
    }

    /* Lifecycles */
    onMounted(() => {
      getBranches();
    });
    return {
      /* Consts */
      columnsTable,
      rows,
      visibilityDialog,
      actionType,
      content,
      visibilityDeleteDialog,
      /* functions */
      handleDialog,
      getBranches,
      handleDeleteBranch,
    };
  },
};
</script>

<style scoped>
.container-segment {
  display: flex;
  flex-direction: column;
}
</style>
