<template>
  <main class="flex column justify-start q-pa-md q-mt-md no-wrap">
    <h4 class="heading-wordNamedBlocked">Palavras bloqueadas</h4>
    <p class="paragraph-wordNamedBlocked">Insira as palavras para bloqueio</p>
    <q-form
      ref="myForm"
      @submit.prevent.stop="
        () => {
          if (flagEdit) {
            updateBadLanguage();
          } else {
            onSubmit();
          }
        }
      "
    >
      <q-input
        class="textarea-wordNamedBlocked"
        bg-color="grey-3"
        v-model="wordNamedBlocked"
        color="default-pink"
        dense
        type="textarea"
        outlined
        :rules="[(val) => !!val || 'O campo é obrigatório.']"
        placeholder="As palavras devem ser separadas por vírgula"
      />
      <div class="flex justify-end">
        <q-btn
          outline
          label="Cancelar edição"
          v-show="flagEdit"
          rounded
          color="default-pink"
          @click="() => changeFlagEdit()"
          class="q-mr-sm"
        />
        <FormButton titleButton="Bloquear Palavras" submit :showIcon="false" />
      </div>
    </q-form>
    <div class="justify-center items-center q-mt-md">
      <Table
        class="table-wordNamedBlocked"
        :list="rows"
        :columns="columnsTable"
        :addEdit="true"
        :addRemove="true"
        ><template v-slot:body="props">
          <div>
            <q-btn
              dense
              round
              color="default-pink"
              title="Editar"
              flat
              @click="() => getBadlanguagesById(props.props.row)"
              icon="o_edit_note"
            />
            <q-btn
              dense
              round
              color="default-pink"
              title="Remover"
              flat
              @click="() => disabled(props.props.row)"
              icon="o_delete_outline"
            />
          </div>
        </template>
      </Table>
    </div>
  </main>
</template>

<script>
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import FormButton from "@/components/admin/buttons/FormButton.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";

/* Vue */
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

/* Quasar */
import { useQuasar } from "quasar";

/* Services */
import CompanyServices from "@/services/CompanyServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

export default {
  name: "Badlanguage",
  components: {
    DefaultBtn,
    FormButton,
    Table,
  },
  setup() {
    /* Constant */
    const breadcrumbs = ref(["Lorni"]);

    const myForm = ref(null);

    const $q = useQuasar();

    const companyServices = new CompanyServices();

    const store = new useStore();

    const company_id = store.state.userdata.company_id;

    const columnsTable = ref([
      {
        name: "id",
        label: "ID",
        align: "center",
        field: (row) => row.id,
        sortable: true,
      },
      {
        name: "Palavras Bloqueadas",
        label: "Palavras Bloqueadas",
        align: "center",
        field: (row) => row.description,
        sortable: true,
      },
      {
        name: "Criado em",
        label: "Criado em",
        align: "center",
        field: (row) => row.created_at,
        format: (row) => `${row.slice(0, 10).split("-").reverse().join("/")}`,
      },
      {
        name: "Criado por",
        label: "Criado por",
        align: "center",
        field: "created_name",
      },
      {
        name: "Editado em",
        label: "Editado em",
        align: "center",
        field: (row) =>
          row.updated_at != null
            ? row.updated_at.slice(0, 10).split("-").reverse().join("/")
            : "",
      },
      {
        name: "Editado por",
        label: "Editador Por",
        align: "center",
        field: "updated_name",
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
      },
    ]);

    const rows = ref([]);

    //----UTILS
    const Alerts = new AlertsClass();

    /* Variable */
    let wordNamedBlocked = ref("");
    let flagEdit = ref(false);
    let idWordSelected = ref(0);
    let wordNamedBlockedUpdate = ref([]);

    async function onSubmit() {
      if (!(await myForm.value.validate())) {
        $q.notify({
          color: "red-5",
          message: "Você precisa preencher todos os campos obrigatórios",
        });
        return;
      }
      let wordNamedBlockedArray = ref(wordNamedBlocked.value.split(","));
      const formData = new FormData();
      wordNamedBlockedArray.value.forEach((element) => {
        if (element.length > 0) {
          formData.append("names", element);
        }
      });
      formData.append("companyId", company_id);
      await companyServices
        .addBadlanguage(formData)
        .then((form) => {
          Alerts.alertOf("Palavras salvas com sucesso!", "green-9");
          wordNamedBlocked.value = "";
          changeFlagEdit();
          getBadlanguages();
        })
        .catch((error) => {
          Alerts.alertOf("Erro ao salvar palavras");
        });
    }

    async function getBadlanguages() {
      await companyServices
        .getAllbadlanguages(company_id)
        .then((response) => {
          rows.value = response.data;
          wordNamedBlockedUpdate.value = [];
          response.data.forEach((element) => {
            wordNamedBlockedUpdate.value.push(element.description);
          });
          store.commit("setBlacklist", wordNamedBlockedUpdate.value);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function disabled(user) {
      $q.notify({
        message: `Deseja remover o usuário ${user.id}`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              disabledBadLanguage(user.id);
            },
          },
        ],
      });
    }

    async function disabledBadLanguage(item) {
      await companyServices
        .disabledBadlanguage(item)
        .then((response) => {
          Alerts.alertOf("Palavra removida!", "green-9");
          getBadlanguages();
        })
        .catch((e) => {
          Alerts.alertOf("Palavra removida!", "green-9");
        });
    }

    function getBadlanguagesById(item) {
      wordNamedBlocked.value = item.description;
      flagEdit.value = true;
      idWordSelected.value = item.id;
    }

    function changeFlagEdit() {
      wordNamedBlocked.value = "";
      flagEdit.value = false;
    }

    async function updateBadLanguage() {
      if (!(await myForm.value.validate())) {
        $q.notify({
          color: "red-5",
          message: "Você precisa preencher todos os campos obrigatórios",
        });
        return;
      }
      let wordNamedBlockedArray = ref(wordNamedBlocked.value.split(","));
      const formData = new FormData();

      formData.append("name", wordNamedBlockedArray.value);
      formData.append("companyId", company_id);
      await companyServices
        .updateBadlanguage(idWordSelected.value, formData)
        .then((form) => {
          Alerts.alertOf("Palavra atualizada com sucesso!", "green-9");
          changeFlagEdit();
          getBadlanguages();
        })
        .catch((error) => {
          Alerts.alertOf("Erro ao atualizar a palavra");
        });
    }
    onMounted(() => {
      getBadlanguages();
    });

    return {
      /* Constant */
      breadcrumbs,

      /* Variable */
      wordNamedBlocked,
      flagEdit,

      /* Const */
      myForm,
      columnsTable,
      rows,

      /* Functions */
      onSubmit,
      getBadlanguagesById,
      disabledBadLanguage,
      disabled,
      changeFlagEdit,
      updateBadLanguage,
    };
  },
};
</script>

<style lang="scss" scoped>
main {
  overflow-y: auto;

  h4,
  h3,
  p {
    margin: 0;
  }
  padding: 19.5px 2.5% 19.5px;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  height: 75vh;
}

.heading-wordNamedBlocked {
  height: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 16px;
}
.paragraph-wordNamedBlocked {
  height: 17px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 14px;
}
.textarea-wordNamedBlocked {
  border-radius: 8px;
}

.textarea-wordNamedBlocked::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #686868;
  opacity: 0.5;
}

tr:has(th) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px;
  line-height: 15px !important;
  color: #000000;
  opacity: 0.5 !important;
}

table td {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000 !important;
}

h3.title-wordNamedBlocked {
  font-family: "Montserrat";
  text-align: right;
  margin-right: 28px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.4;
}
</style>
