<template>
  <q-dialog v-model="isShowDialog" persistent>
    <q-card
      style="width: 50%; min-width: 400px; max-width: 90vw; min-height: 438px"
    >
      <q-card-section class="container">
        <div class="header">
          <h1>{{ actionType ? "Adicionar humor" : "Editar humor" }}</h1>
          <div class="close">
            <q-btn
              color="default-pink"
              icon="close"
              round
              @click="$emit('closeDialog')"
            />
          </div>
        </div>
        <div class="content">
          <q-img
            v-show="!actionType"
            :src="urlAwsBucket + moodForm.image"
            style="width: 174px; height: 174px; border-radius: 50%"
            spinner-color="primary"
            spinner-size="82px"
          />
          <div class="container-form">
            <q-uploader
              v-show="actionType"
              label="Carregar Imagem"
              color="default-pink"
              flat
              bordered
              accept=".jpg ,.png"
              style="max-width: 300px"
              :filter="getFile"
              batch
            />
            <div>
              <label for="title" class="titleLabel"
                >Título <span>*</span></label
              >
              <q-input
                autofocus
                id="title"
                v-model="moodForm.title"
                type="text"
                outlined
                color="default-pink"
                placeholder="Digite aqui o título do humor"
                :rules="[(val) => !!val || 'Por favor digite o titulo!']"
              />
            </div>
          </div>
        </div>
      </q-card-section>
      <q-card-actions align="right" style="width: 95%">
        <div class="q-gutter-md">
          <q-btn
            unelevated
            rounded
            color="default-pink"
            label="Cancelar"
            size="md"
            no-caps
            outline
            v-close-popup
          />
          <q-btn
            unelevated
            rounded
            color="default-pink"
            label="Salvar"
            size="md"
            no-caps
            @click="moodStorage"
            :disable="disable"
            v-close-popup
          />
        </div>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, onMounted, reactive, watch, ref } from "vue";

/* vuex */
import { useStore } from "vuex";

/* Services */

import UserServices from "@/services/UserServices";

import { ENV } from "@/utils/env";

/* Quasar */
import { useQuasar } from "quasar";

export default {
  name: "DialogMood",
  props: {
    confirm: {
      required: true,
      type: Boolean,
    },
    actionType: {
      required: true,
      type: Boolean,
    },
  },
  setup(props) {
    const $q = new useQuasar();
    const userServices = new UserServices();
    const store = new useStore();
    const urlAwsBucket = ENV.URL_AWS_BUCKET;

    const isShowDialog = ref(props.confirm);

    const moodForm = reactive({
      image: null,
      title: "",
    });

    /* lifecycles */

    const disable = computed(() => {
      if (props.actionType) {
        return moodForm.title != "" && moodForm.image !== null ? false : true;
      } else {
        return moodForm.title != "" ? false : true;
      }
    });

    const currentMood = computed(() => {
      return store.state.mood.moodUpdate;
    });

    onMounted(() => {
      if (props.actionType == false) {
        moodForm.title = currentMood.value.title;
        moodForm.image = currentMood.value.path;
      }
    });

    watch(
      () => props.confirm,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    /* Functions */
    function getFile(file) {
      moodForm.image = file[0];
      return file;
    }

    async function moodStorage() {
      const formData = new FormData();

      if (props.actionType) {
        if (moodForm.image != null) {
          formData.append("FileImage", moodForm.image);
        }

        await userServices
          .addEmoji(moodForm.title, formData)
          .then((response) => {
            if (response.data) {
              store.commit("setMood", { storingMood: true });
              $q.notify({
                message: "O seu humor foi cadastrado com sucessso",
                color: "green",
                position: "top",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        formData.append("emojiId", currentMood.value.id);

        if (moodForm.title != "") {
          formData.append("title", moodForm.title);
        }

        console.log(currentMood.value.id, moodForm.title);
        await userServices
          .updateEmojji(currentMood.value.id, moodForm.title)
          .then((response) => {
            store.commit("setMood", { storingMood: true });
            $q.notify({
              message: "O seu humor foi atualizado com sucessso",
              color: "green",
              position: "top",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    return {
      disable,
      getFile,
      moodForm,
      moodStorage,
      urlAwsBucket,
      isShowDialog,
    };
  },
  emits: ["closeDialog"],
};
</script>

<style scoped>
.q-dialog {
  border-radius: 1.875rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  width: 90%;
  display: flex;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.75rem;
  width: 90%;
}

h1 {
  flex: 2;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: #000000;
}

.container-form {
  margin-top: 1.875rem;
  width: 90%;
}

.titleLabel {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #141519;
  margin-bottom: 0.25rem;
}

.titleLabel > span {
  color: var(--q-primary, #fe4e64);
}

.q-uploader {
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .content {
    flex-direction: row;
    justify-content: space-around;
  }

  .container-form {
    margin-left: 2.1875rem;
    flex: 2;
  }
}
</style>
