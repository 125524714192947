<template>
  <div class="flex column justify-start q-pa-md q-mt-md no-wrap container">
    <main>
      <q-uploader
        multiple
        batch
        :filter="getFiles"
        color="default-pink"
        flat
        bordered
        accept=".webp, .jpg ,.png"
        style="max-width: 300px"
      >
        <template v-slot:header="scope">
          <div class="row no-wrap items-center q-pa-sm q-gutter-xs">
            <q-btn
              icon="delete"
              @click="scope.removeQueuedFiles"
              round
              dense
              flat
            >
              <q-tooltip>Deletar todas as imagens</q-tooltip>
            </q-btn>
            <div class="col">
              <div class="q-uploader__title">Upload de imagens</div>
              <div class="q-uploader__subtitle text-bold">
                Imagens <strong>.jpg, .png, .webp</strong>
              </div>
            </div>
            <q-btn
              v-if="scope.canAddFiles"
              type="a"
              icon="add_box"
              @click="scope.pickFiles"
              round
              dense
              flat
            >
              <q-uploader-add-trigger />
              <q-tooltip>Adicionar imagem</q-tooltip>
            </q-btn>
            <q-btn
              v-if="scope.canUpload"
              icon="cloud_upload"
              @click="sendFiles"
              round
              dense
              flat
            >
              <q-tooltip>Enviar imagens</q-tooltip>
            </q-btn>
          </div>
        </template>
        <template v-slot:list="scope">
          <div>
            <q-list separator>
              <q-item v-for="file in scope.files" :key="file.__key">
                <q-item-section v-if="file.__img" thumbnail>
                  <img :src="file.__img.src" />
                </q-item-section>
                <q-item-section top side>
                  <q-btn
                    class="gt-xs"
                    size="12px"
                    flat
                    dense
                    round
                    icon="delete"
                    @click="scope.removeFile(file)"
                  />
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </template>
      </q-uploader>
      <section class="gallery">
      <div v-for="(data, index) in pagination" :key="index">
        <CardImg
          :enableActionRemove="true"
          :content="data"
          @upload="getPhotos"
        />
      </div>
    </section>
    <q-pagination
      color="default-pink"
      direction-links
      boundary-links
      v-model="current"
      :min="currentPage"
      :max="Math.ceil(allAvatars?.length / totalItemsPerPage)"
      class="q-mt-md self-end q-mr-xl"
    />
    </main>
    
    
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde o envio ser finalizado!"
    >
    </q-inner-loading>
  </div>
</template>

<script>
/* Quasar */
import { useQuasar } from "quasar";

/* Vue */
import { onMounted, computed, ref } from "vue";

/* Components */
import CardImg from "./cards/CardImg.vue";

/* Services */

import UserServices from "@/services/UserServices";

export default {
  name: "PhotoGallery",
  components: {
    CardImg,
  },
  setup(props) {
    /* Quasar */
    const $q = new useQuasar();

    /* Services */
    const userServices = new UserServices();

    /* Constants */
    const currentPage = ref(1);
    const current = ref(1);
    const totalItemsPerPage = ref(12);
    const allAvatars = ref([]);
    const filesImages = ref([]);
    const loading = ref(false);

    /* LifeCycles */
    const pagination = computed(() => {
      return allAvatars.value.slice(
        (current.value - 1) * totalItemsPerPage.value,
        (current.value - 1) * totalItemsPerPage.value + totalItemsPerPage.value
      );
    });

    onMounted(() => {
      getPhotos();
    });

    /* Functions */

    function getFiles(files) {
      filesImages.value = files;
      return files;
    }

    async function sendFiles() {
      loading.value = true;
      const formData = new FormData();
      filesImages.value.forEach((file) => {
        formData.append("FileImage", file);
      });

      await userServices
        .addAvatars(formData)
        .then((response) => {
          loading.value = false;
          if (response.data) {
            $q.notify({
              message: "As suas imagens foram salvas com sucesso!!",
              color: "green",
              position: "top",
            });
            getPhotos();
          } else {
            $q.notify({
              message:
                "As suas imagens não foram salvas, por favor tente mais tarde!",
              color: "red-9",
              position: "top",
            });
          }
        })
        .catch((error) => {
          loading.value = false;
          console.log(error);
        });
    }

    async function getPhotos() {
      userServices
        .getAvatars()
        .then((response) => {
          allAvatars.value = response.data;
          if (response.data.length == 0) {
            $q.notify({
              message: `Você ainda não possui nenhuma imagem cadastrada! Por favor utilize o upload de imagens para cadastrá-las`,
              color: "red-9",
              position: "top",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return {
      /* Fuctions */
      getFiles,
      getPhotos,
      sendFiles,
      /* Constants */
      currentPage,
      current,
      totalItemsPerPage,
      allAvatars,
      loading,
      /* LifeCycles */
      pagination,
    };
  },
};
</script>

<style lang="scss" scoped>

.container {
  main{
    height: 70vh;
    display: flex;
    flex-direction: column;
  }
  overflow-y: scroll;

}

.gallery {
 
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 200px));
  margin-top: 1.875rem;
  justify-content: center;
  gap: 30px;
}
</style>
