<template>
  <div class="q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="home" :query="{}">
      <!--         <DefaultBtn
          titleButton="Novo Post"
          @click="
            () => {
              (isShowPostForm = true), (postEdit = null);
            }
          "
          v-show="btnAddPost"
        /> -->
    </Mural>
  </div>
  <q-tabs
    v-model="tab"
    @click="
      () => {
        changeBreadcrumbs(tab);
      }
    "
    active-color="default-pink"
    no-caps
    class="q-mt-md"
    align="justify"
  >
    <q-tab name="badLanguages" label="Palavras Bloqueadas" v-if="canUse" />
    <q-tab name="photoGallery" label="Galeria de Imagens" v-if="canUse" />
    <q-tab name="moodMangement" label="Administração de humor" />
    <q-tab name="segment" label="Segmento" v-if="canUse" />
  </q-tabs>
  <q-tab-panels v-model="tab" animated>
    <q-tab-panel name="badLanguages">
      <BadLanguages />
    </q-tab-panel>
    <q-tab-panel name="photoGallery">
      <PhotoGallery />
    </q-tab-panel>
    <q-tab-panel name="moodMangement">
      <MoodManagement />
    </q-tab-panel>
    <q-tab-panel name="segment">
      <Segment />
    </q-tab-panel>
  </q-tab-panels>
</template>

<script>
/* Components */
import Mural from "@/components/Mural.vue";
import BadLanguages from "@/components/admin/company/BadLanguages.vue";
import PhotoGallery from "@/components/admin/company/PhotoGallery.vue";
import MoodManagement from "@/components/admin/company/MoodManagement.vue";
import Segment from "@/components/admin/company/Segment.vue";

/* Vue */
import { ref, onMounted } from "vue";
/* 
import CanUserAccessClass from "@/utils/CanUserAccessClass"; */

import CanUserAcessClass from "@/utils/CanUserAccessClass";

export default {
  name: "CompanyConfiguration",
  components: {
    Mural,
    BadLanguages,
    PhotoGallery,
    MoodManagement,
    Segment,
  },
  setup() {
    /* Constant */
    const tab = ref("badLanguages");
    const breadcrumbs = ref(["Palavras Bloqueadas"]);
    const canUserAcess = new CanUserAcessClass();
    const canUse = ref();

    onMounted(() => {
      ValidateActionsPage("users-view");
    });

    async function ValidateActionsPage(routeName) {
      if (canUserAcess.canUserAccess(routeName, false)) {
        canUse.value = true;
      } else {
        canUse.value = false;
        breadcrumbs.value = ["Administração de humor"];
        tab.value = "moodMangement";
      }
    }

    function changeBreadcrumbs(value) {
      let bread = [
        "Palavras Bloqueadas",
        "Galeria de Imagens",
        "Administração de humor",
        "Segmentos",
      ];

      let tabs = ["badLanguages", "photoGallery", "moodMangement", "segment"];

      let indexTab = tabs.findIndex((tab) => tab.includes(value));

      breadcrumbs.value[0] = bread[indexTab];
    }

    return {
      /* Constant */
      breadcrumbs,
      tab,
      canUse,
      /* Functions*/
      changeBreadcrumbs,
      ValidateActionsPage,
    };
  },
};
</script>

<style lang="scss" scoped>
.q-tab__label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem !important;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #666666;
}
</style>
